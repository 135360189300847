export const environment: any = {
  production: true,
  apiUrl: '//uat.progetsecure.com/api/secure',
  version: '1.7.0',
  sentry: {
    dsn: 'https://c6bc99434c67361431eb25604b77b4dd@o4506381833601024.ingest.sentry.io/4506382059372544',
    replay: false,
  },
  firstDayOfWeek: 1,
};
